
import 'alpinejs'


import Flickity from "flickity"
import "flickity-fade"
import "flickity-as-nav-for"
import { isMobile } from 'mobile-device-detect';


window.brautican = function (dataTimer) {
  return {
    loaded: false,
    openedNav:false,
    listen:false,
    carousel:null,
    active:-1,
    startClicked:false,
    showStart:false,
    finishedEnter:false,
    isTouch:isMobile,
    dataTimer:dataTimer,
    isDate:false,

    init(){


      this.active =0;


    },
    onClick()
    {
      if (!this.listen) return
    },
    startExpo()
    {
      this.startClicked = true;
      this.showStart = false;
      setTimeout( ()=> {
        this.hideAndReady()
      }, 3200)
    },
    checkDate() {



      if (this.isDate == true) return;
      var milliseconds = Date.now() - Date.parse(this.dataTimer);
      if (milliseconds >= 0) this.isDate = true;
      else this.isDate = false;
      setTimeout( ()=> {
        this.checkDate()
      }, 1000)

    },
    onLoaded() {
     this.loaded = true;
      this.showStart = true;
      this.checkDate();
    },
    hideAndReady() {
      this.openedNav = true;
      this.finishedEnter = true;
      setTimeout( ()=> {
        this.listen = true;
      }, 1000)
    },
    close() { this.show = false },
    isOpen() { return this.show === true },

    onMousemove(e)
    {
      let posY = e.screenY;
      var wh = window.innerHeight
      if (!this.listen) return
      if (posY > 100 && posY < (wh-100) ) this.openedNav = false;
      else this.openedNav = true;
    }
  }
}

